<template>
    <v-card
    width="240px"
    class="secondary"
    
    dark>
    <!-- style="min-width: 240px; max-width: 240px" -->
    
    <div class="text-center pt-4">
        <v-avatar size="100" class="">
            <img class="avatar-bordered bold" v-if="item.avatar"
                :src="item.avatar">
            <div  v-else> <font-awesome-icon icon="user-circle" class="text-primary" size="6x"/></div>
        </v-avatar>
    </div>
    <v-card-text class="text--primary">
        <p class="h6 text-primary text-center text-truncate" :title="item.title">{{ item.title }}</p>
        <p v-if="item.subtext" class="text-center text-truncate" :title="item.subtext">{{ item.subtext }}</p>
        <div v-else class="py-5">
            
        </div>
    </v-card-text>

    <div class="text-center">
        <v-btn small outlined rounded color="primary" :to="item.$route">
            <span class="text-primary">{{btnLabel}}</span>
        </v-btn>
    </div>
    <br>
  </v-card>
</template>
<script>
export default {
    props: {
        item: Object,
        btnLabel: {
            type: String,
            default: 'Pesquisar'
        }
    },
    components: {
    }
}
</script>