<template>
    <v-container>
        <p class="text-uppercase text-primary headline m-1x">Base de dados</p>
        <v-row 
          justify="center">
            <v-col cols="12" md="12" lg="3" class="mt-3">
                <v-card class="secondary pa-4">
                    <v-text-field class="mt-2" dense clearable
                        v-model="dbFilter.query"
                        label="Procuro por"
                        placeholder="Autor X"
                        outlined></v-text-field>
                    
                    <v-autocomplete class="mt-2" clearable
                        v-model="dbFilter.city"
                        :items="filters.cities"
                        outlined
                        dense
                        chips
                        small-chips
                        label="Em Cidade"
                    ></v-autocomplete>

                    <v-autocomplete class="mt-2" clearable
                        v-model="dbFilter.country"
                        :items="filters.countries"
                        outlined
                        dense
                        chips
                        small-chips
                        label="Em País"
                    ></v-autocomplete>
                    
                    <v-btn color="primary" small @click="search" :loading="loading">
                        <span class="black--text" >Pesquisar</span>
                    </v-btn>
                </v-card>

                <v-card class="mt-8" :loading="loadingFilter">
                    <v-list class="secondary" dense>
                        <v-subheader><p class="text-uppercase">Categoria</p></v-subheader>
                        <v-list-group
                            v-for="item in items" :key="item.id" v-model="item.active">
                            <template v-slot:activator>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </template>

                            <v-list-item class="ml-4" 
                                v-for="category in item.categories" :key="category.id"
                                link dark>
                                <v-checkbox class="ma-0 pt-2" color="primary" dense 
                                    v-model="item.selections"
                                    :value="category.id">
                                        
                                    </v-checkbox>
                                    <template>
                                            <span class="mb-2" 
                                                :class="{'text-primary': item.selections != null &&  item.selections.includes(category.id)}">
                                                {{ category.title }}</span>
                                        </template>
                            </v-list-item>
                            
                        </v-list-group>
                    </v-list>
                </v-card>
                
            </v-col>
            <v-col cols="12" md="12" lg="9">
                <v-row justify="center">
                    <template v-if="authors && authors.length > 0">
                        <v-col cols="12" sm="4" md="3" lg="3" v-for="item in authors" :key="item.id">
                            <v-row
                                align="center"
                                justify="center" no-gutters>
                                <CardAvatar 
                                    :item="item" btnLabel="Ver"/>
                            </v-row>
                        </v-col>
                    </template>
                    <v-col v-else>
                        <PostContainer :headless="true" v-if="!loading">
                            <p clas="h3">Nenhum resultado encontrado.</p>
                        </PostContainer>
                    </v-col>

                    
                </v-row>
                <br>
                <div class="text-center" v-if="hasMore">
                    <v-btn @click="fetchMore" outlined rounded :loading="loading">
                        <font-awesome-icon icon="redo-alt" /> <span> Mostrar Mais</span>
                    </v-btn>
                </div>
                
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CardAvatar from '../CardAvatar';
import PostContainer from '../PostContainer';

import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    data: () => ({
        locations: [],
        dbFilter: {
            city: null,
            query: null,
            country: null,
        }
    }),
    created () {
        this.loadFilters();
        this.search();
    },
    computed: {
        ...mapGetters('author/database', [ 'hasMore']),
        ...mapState('author/database', ['filters', 'loadingFilter','authors', 'hasMore', 'loading']),
        items () {
            return this.filters.categories || [];
        },
        filter() {
            return { 
                limit: 20,
                categories: this.getSelectedCategories(),
                address: this.dbFilter.city,
                query: this.dbFilter.query,
                country: this.dbFilter.country
            }
        }
    },
    methods: { 
        ...mapActions('author/database', {
            fetchAuthors: 'list',
            loadMore: 'loadMore',
            loadFilters: 'loadFilters',
        }),
        getSelectedCategories() {
            return this.items.filter(i1 => i1.selections).map(i1 => i1.selections);
        },
        fetchMore() {
            this.loadMore(this.filter);
        },
        search() {
            this.fetchAuthors(this.filter);
        }
    },
    components: {
        CardAvatar,
        PostContainer,
    },
    
    
}
</script>