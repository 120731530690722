<template>
    <div>
        <Database />
    </div>
</template>

<script>
import Database from '../components/author/Database';

export default {
    data: () => ({}),
    components: {
        Database,
    },
    
    
}
</script>